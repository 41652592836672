
















import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class FilterSelect extends Vue {
  @Prop() value!: number[];

  @Prop() options!: SelectOption[];

  @Prop() placeholder?: string;

  @Prop({ default: true }) multiple?: boolean;

  localValue: number[] = [];

  open = false;

  toggleOption(option: SelectOption) {
    if (!this.multiple) {
      this.localValue = [];
    }
    this.pushOrSlice(this.localValue, option.id);

    this.handleInput();
  }

  @Emit("input")
  handleInput(): number[] {
    return this.localValue;
  }

  @Watch("value", { immediate: true })
  handleValueChanged(value: number[]) {
    this.localValue = value;
  }

  private get selectedValues(): string {
    const limit = 2;
    const total: number = this.localValue.length;
    const more = total - limit;
    const response: string = this.options
      .filter((option: SelectOption) => this.localValue.includes(option.id))
      .splice(0, limit)
      .map((option: SelectOption) => option.label)
      .join(", ");

    return `${response} ${more > 0 ? ", " + more + " andere" : ""}`;
  }

  private pushOrSlice(array: number[], prop: number): number[] {
    if (array.includes(prop)) {
      array.splice(array.indexOf(prop), 1);
    } else {
      array.push(prop);
    }

    return array;
  }
}
